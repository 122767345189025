@import '../../../../sassStyles/common';

.cookie-banner__container {
  width: 100vw;
  background-color: $white;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
  z-index: 10;
  position: fixed;
  top: 0;
}

.cookie-banner__content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 30px 15px;

  >.text {
    font-size: 1.2rem;
    font-weight: 400;
    color: $primary;
  }
  >.buttons-container {
    display: flex;
    gap: 10px;
  }
}

.Rectangle-27,
.open,
.close {
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 435px) {
  .cookie-banner__container {
    width: 100%;
  }

  .cookie-banner__content {
    flex-direction: column;
    gap: 15px;

    >.text {
      font-size: .9rem;
      text-align: center;
    }
  }
}
