@import '../../../sassStyles/common';
@import '../../../sassStyles/links';

.contact-us-body {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-start;
  background-color: $white;
  margin: 0 16px;
}

.contact-us-input {
  display: flex;
  padding-top: 24px;
  flex-grow: 1;

  &.checkbox {
    flex-grow: 1;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.my-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.contact-us-title {
  display: flex;
  align-self: stretch;
  flex-grow: 0;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: $primary;

  &.landing {
    color: $secondary;
  }
}

.contact-us-message {
  align-self: stretch;
  flex-grow: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: $secondary;
}
