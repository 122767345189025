@import '../../../sassStyles/common';
@import '../../../sassStyles/links';
@import '../../../sassStyles/fade';

.landing-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 104px;
  overflow: auto;

  @media (max-width: 1095px) {
    margin-top: 72px; 
  }
}

.sidenav-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.intro-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
  background-image: url('../../../img/background/landing.svg');
  background-size: cover;
  z-index: 1;

  .impulsa-text {
    font-size: 64px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $white;
    margin-top: 116px;
    margin-left: 15%;
    margin-right: 10%;
  }

  .descubre-text {
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: center;
    margin-top: 14px;
    margin-left: 15%;
    margin-right: 10%;
    color: $white;
    margin-bottom: 164px;
  }
}

.como-funciona-container {
  margin-top: -122px;
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: center;
  width: 100%;
  background-color: $landing-step-background;
  overflow: hidden;
  padding-bottom: 209px;

  .crea-tu-zircle-container {
    scroll-margin-top: 104px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    padding: 64px;
    border-radius: 24px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
    background-color: $white;
    z-index: 2;
    margin: 32px 149px 0 149px; 
    flex-grow: 1;
  
    .text {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      padding: 0;
      flex-basis: 50%;
  
      .title {
        flex-grow: 0;
        font-size: 42px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: $secondary;
      }
  
      .description {
        flex-grow: 0;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        text-align: left;
        color: $secondary;
      }
    }
  
    .image {
      display: flex;
      border-radius: 24px;
      flex-grow: 1;
      flex-basis: 50%;

      >img {
        width: 100%;
      }
    }
  }

  >img {
    width: 32px;
    height: 32px;
    margin-top: 8px;
    object-fit: contain;
    cursor: pointer;
    z-index: 2;

    @include material-effect($white);
  }

  .como-funciona-title {
    scroll-margin-top: 104px;
    margin-top: 84px;
    font-size: 52px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: $secondary;
  }

  .steps-container {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: baseline;
    gap: 75px;
    margin-top: 68px;
    margin-left: 149px;
    margin-right: 149px;

    .step-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 36px;
      flex-basis: 100%;

      .img-container {
        display: flex;
        border-radius: 100px;
        background-color: $white;
        align-items: center;
        justify-items: center;
        padding: 60px;
      }
  
      .text-container {
        display: flex;
        align-self: stretch;
        flex-grow: 1;
        flex-direction: column;
        justify-items: flex-start;
        align-items: center;
        gap: 8px;
  
        .title {
          flex-grow: 0;
          font-size: 22px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.09;
          letter-spacing: normal;
          text-align: center;
          color: $secondary;
        }

        .description {
          flex-grow: 0;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: normal;
          text-align: center;
          color: $secondary;
        }
      }
    }
    .arrow-container {
      display: flex;
      border-radius: 100px;
      background-color: $white;
      align-items: center;
      justify-content: center;
      padding: 5px;
    }
  }
}

.conoce-nuestros-beneficios-container {
  scroll-margin-top: 104px;
  display: flex;
  flex-direction: column;
  gap: 133px;
  align-items: flex-start;

  >.title {
    font-size: 52px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: $secondary;
    margin: 80px 149px 0 149px;
  }

  .beneficio-container {
    display: flex;
    flex-direction: row;
    justify-items: center;
    gap: 95px;
    margin: 80px 149px 0 149px;

    &.empleados {
      flex-direction: row-reverse;
    }

    >img {
      background-color: $white;
      width: 100%;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-items: flex-start;
      align-items: flex-start;
      gap: 32px;

      >.title {
        font-size: 52px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: $secondary;
      }

      .description {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $secondary;
      }
    }
  }

  .saved-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $secondary;
    gap: 54px;
    padding-top: 84px;
    padding-bottom: 103px;
    width: 100%;

    >.title {
      font-size: 42px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: center;
      color: $white;
    }
    .items-container {
      display: flex;
      flex-direction: row;
      gap: 79px;
      justify-items: center;

      .item-container {
        display: flex;
        flex-direction: column;
        align-items: center;
  
        >img {
          width: 84px;
          height: 84px;
          object-fit: contain;
          margin-bottom: 24px;
        }
  
        .number-saved {
          font-size: 52px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          color: $white;
        }
  
        .description {
          flex-grow: 0;
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: normal;
          text-align: center;
          color: $white;
        }
      }
    }

  }
}

.nuestros-principios-container {
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: $landing-step-background;
  z-index: 1;

  >.title {
    font-size: 52px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: $secondary;
    margin: 132px 267px 11px 149px;
  }

  >.description {
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: $secondary;
    margin: 0 267px 35px 149px;
  }
  
  .img-list-container {
    display: flex;
    overflow-x: hidden;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 30px 0;
    gap: 32px;
    flex-wrap: nowrap;

    .img-container {
      display: flex;
      border-radius: 24px;
      width: 315px;
      height: 323px;
      
      >img {
        width: 100%;
        height: 100%;
        border-radius: 24px;
      }
    }
  }

  .text-carrousel {
    display: flex;
    align-items: stretch;
    gap: 100px;
    margin-left: 149px;
    margin-bottom: 182px;
    margin-right: 267px;
    
   >.text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 13px;
      flex-basis: 900px;

      &.animating {
        @include fade-out-and-fade-in();
      }

      >.title {
        font-size: 42px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        text-align: left;
        color: $secondary;
      }

      >.description {
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: $secondary;
      }
    }

    .navigation-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 35px;

      .counter-text {
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $secondary;
      }

      .arrow-container {
        display: flex;
        border-radius: 100px;
        background-color: $white;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        cursor: pointer;
        @include material-effect($white);
      }
    }
  }
}

.confia-empresa-container {
  margin-top: -136px;
  display: flex;
  overflow-x: hidden;
  gap: 93px;
  background-color: $card-border-selected;
  width: 100%;

  .confia-empresa-text-container {
    display: flex;
    align-items: flex-start;
    justify-items: flex-start;
    flex-direction: column;
    gap: 32px;
    margin-top: 200px;

    >.text {
      margin-left: 149px;
      flex-grow: 0;
      font-size: 42px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: $white;
    }
  
    >.brands-container {
      margin-left: 149px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 64px;

      >img {
        max-width: 196px;
        max-height: 83px;
      }
    }
  }

  .confianza-image-container {
    align-self: flex-end;
    width: 438px;
    height: 523px;
    margin-right: 149px;
    margin-bottom: 64px;
    z-index: 2;
    border-radius: 24px;
  }

}

.contacta-container {
  scroll-margin-top: 104px;
  display: flex;
  align-items: flex-start;
  gap: 32px;
  margin: 100px 149px;

  .left-container {
    display: flex;
    flex-direction: column;
    margin-top: 49px;

    >.title {
      align-self: stretch;
      flex-grow: 0;
      font-size: 28px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: $secondary;
    }

    >.description {
      margin-top: 24px;
      color: $secondary;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    .contacto-field-container {
      margin-top: 48px;
      display: flex;
      gap: 24px;
      justify-items: center;

      >.icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        flex-grow: 0;
        background-color: $primary;
        border-radius: 100px;
      }

      >.text-container {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-items: flex-start;

        >.title {
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
          color: $primary;
        }
        
        >.description {
          font-size: 18px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.22;
          letter-spacing: normal;
          text-align: left;
          color: $black;
        }
      }
    }
  }

  .right-container {
    display: flex;
    padding: 48px;
    flex-basis: 150%;
    align-items: center;
    gap: 32px;
    border-radius: 24px;
  }
}

.footer-links-container {
  display: flex;
  justify-items: flex-start;
  align-items: flex-start;
  gap: 120px;
  padding: 64px 571px 64px 149px;
  background-color: $primary;
  flex-grow: 1;

  .links-container-list {
    display: flex;
    gap: 130px;
    align-items: flex-start;

    .links-container {
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 24px;
      padding: 0;
  
      >.title {
        flex-grow: 0;
        font-size: 22px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.09;
        letter-spacing: normal;
        text-align: left;
        color: $white;
      }
  
      >.link {
        cursor: pointer;
        flex-grow: 0;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: $white;
      }
    }
  }
}

.footer-copyright-container {
  padding: 24px 0 24px 149px;
  background-color: $copyright-background;

  .copyright-text {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: $white;
  }
}

@media (max-width: 435px) {

  .intro-container {

    .impulsa-text {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      margin-top: 48px;
    }

    .descubre-text {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      margin-bottom: 128px;
    }
  }

  .landing-container {
    margin-top: 72px;
  }

  .como-funciona-container {

    padding-bottom: 80px;

    .steps-container {
      flex-direction: column;
      align-items: center;
      gap: 42px;
      margin-top: 32px;
      margin-left: 16px;
      margin-right: 16px;
    }

    .crea-tu-zircle-container {
      flex-direction: column;
      padding: 24px;
      margin: 16px 16px 0 16px; 
  
      .text {
  
        .title {
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }
  
        .description {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    .como-funciona-title {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
  }

  .conoce-nuestros-beneficios-container {
    align-items: center;
    gap: 81px;

    >.title {
      margin: 80px 16px 36px 16px;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }

    .beneficio-container {
      flex-direction: column;
      gap: 24px;
      margin: 0 16px;

      &.empresa {
        margin-top: -80px;
      }

      &.empleados {
        flex-direction: column;
      }

      .text {
        
        gap: 4px;
        >.title {
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }

        .description {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    .saved-container {

      padding-top: 80px;

      >.title {
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
        text-align: center;
        margin: 0 16px;
      }

      .items-container {
        flex-direction: column;
        gap: 72px;
      }
    }
  }

  .nuestros-principios-container {

    >.title {
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      margin: 80px 16px 10px 16px;
    }

    >.description {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin: 0 16px 48px 16px;
    }

    .img-list-container {
      padding: 0;
      gap: 16px;
  
      .img-container {
        width: 288px;
        height: 323px;
      }
    }

    .text-carrousel {
      flex-direction: column;
      gap: 24px;
      margin: 3px 36px 80px 36px;

      >.text-container {
        flex-basis: 100px;

        >.title {
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: 32px;
        }
  
        >.description {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }

      .navigation-container {
        align-self: center;

        .counter-text {
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        .arrow-container {
          height: 32px;
          width: 32px;
        }
      }
    }
  }

  .confia-empresa-container {
    margin-top: 0;
    align-items: center;
    justify-items: center;

    .confia-empresa-text-container {
      align-items: center;
      justify-items: center;
      margin: 80px 16px 0 16px;

      >.text {
        margin-left: 0;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }

      .brands-container {
        margin-left: 0;
        flex-direction: column;
        gap: 28px;
        margin-bottom: 80px;
      }
    }

  }

  .contacta-container {
    flex-direction: column;
    gap: 32px;
    margin: 80px 16px 56px 16px;

    .left-container {
      margin-top: 0;
    }
    
    .right-container {
      padding: 32px 24px;
      
      >.contact-us-body {
        margin: 0;
      }
    }
  }

  .footer-links-container {
    flex-direction: column;
    gap: 48px;
    padding: 56px 16px 48px 16px;

    .links-container-list {
      gap: 16px;
      width: 100%;
    }
  }
  
  .footer-copyright-container {
    padding: 24px 16px;
  }
}