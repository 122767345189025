@import '../../../../sassStyles/common';

.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 24px;
  flex-grow: 0;

  &.lg {
    max-height: 48px;
    flex-grow: 1;
  }
  
  &.sm {
    max-height: 18px;
    padding: 8px 20px;
  }

  &.round {
    padding: 12px 12px;
  }

  &.btn-primary {
    background-color: $btn-primary;
    @include material-effect($primary);
  }

  &.btn-secondary {
    border: solid 2px $primary;
    @include material-effect($primary);
  }

  &.btn-error {
    border: solid 2px $error;
    @include material-effect($error);
  }

  &.btn-primary-alt {
    background-color: $white;
    @include material-effect($white);
  }

  &.disabled {
    background-color: $disabled;
  }

  .icon {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
  }

  .label {
    flex-grow: 0;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    align-self: center;
    color: $primary;
    cursor: pointer;

    &.disabled {
      cursor: initial;
      color: $disabled;
    }
  }
}
