@import '../../../../sassStyles/common';

.input-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  flex-grow: 1;

  .input-title {
    margin: 0 0 4px 0;
    height: 20px;
    align-self: stretch;
    flex-grow: 1;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }

  .input-container {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 12px 16px;
    border-radius: 24px;
    border: solid 1px $secondary;
    background-color: $white;

    &:focus-within {
      border: solid 2px $active;
    }

    &.error {
      border: solid 2px $error;
    }

    input {
      width: 100%;
      min-width: 0;
      height: 24px;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $secondary;
      animation: blink 1s infinite;

      @keyframes blink {
        50% {
          caret-color: transparent;
        }
      }

      &::placeholder {
        color: $disabled;
      }

      &:focus {
        caret-color: $active;
      }

      &.error {
        caret-color: $error;
        color: $error;
      }
    }

    textarea {
      width: 100%;
      min-width: 0;
      height: 48px;
      border: none;
      outline: none;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: $secondary;
      animation: blink 1s infinite;

      @keyframes blink {
        50% {
          caret-color: transparent;
        }
      }

      &::placeholder {
        color: $disabled;
      }

      &:focus {
        caret-color: $active;
      }

      &.error {
        caret-color: $error;
        color: $error;
      }
    }
  }
}

.input-icon-r {
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
}

.input-additional-row {
  height: 19px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 0;
}

.input-feedback {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 0;

  .icon {
    width: 16px;
    height: 16px;
    flex-grow: 0;
    object-fit: contain;
  }
  
  .text {
    height: 16px;
    flex-grow: 1;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: $black;

    &.error {
      color: $error;
    }

    &.success {
      color: $border-success;
    }
  }
}

.input-error-feedback {
  align-self: stretch;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  padding: 0;

  .alert {
    width: 16px;
    height: 16px;
    flex-grow: 0;
    object-fit: contain;
  }

  .error-text {
    height: 16px;
    flex-grow: 1;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: $error;
  }
}

.input-helper {
  height: 19px;
  flex-grow: 1;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $counter;
}

.input-counter {
  width: 11%;
  height: 100%;
  flex-grow: 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: $counter;

  &.alone {
    margin-left: auto;
  }

  .input-actual-count {
    font-weight: 600;
  }
}

@media (min-width: 435px) {
  .input-body {
    width: 100%;
  }
}
