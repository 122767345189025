@import '../../../../sassStyles/common';

.add-post-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 64px 151px 0 310px;
}

.add-post-container {
  background-color: $white;
  justify-content: center;
  align-self: center;
  margin: 0 auto;
  width: 100%;
  display: flex;
}

.helper-post-container {
  display: flex;
  flex-direction: column;
  width: 546px;
  background-color: $white;
  align-self: center;
  justify-content: center;
  justify-self: center;
  align-items: center;
  margin: 0 32px;
}

.add-post-navigator {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  width: 100%;
}

.add-image-container {
  display: flex;
  gap: 32px;
  align-self: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

.helper-post-title {
  height: 28px;
  flex-grow: 0;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: $primary;
}

.helper-post-description {
  align-self: stretch;
  flex-grow: 0;
  font-family: Baloo Bhai 2;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $black;
  margin-top: 12px;
  margin-bottom: 24px;
}

.helper-post-button {
  width: 100%;
}

.add-post-input {
  padding-top: 24px;

  &.title {
    padding-top: 0px;
  }
}

.add-post-title {
  align-self: stretch;
  flex-grow: 0;
  font-size: 32px;
  margin-top: 24px;
  margin-bottom: 24px;
  font-weight: bold;
  line-height: 1.17;
  text-align: center;
  color: $primary;

  &.third-step {
    margin-bottom: 8px;
  }
}

.add-image-container-message {
  margin-top: 36px;
}

.add-post-subtitle {
  height: 72px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-family: Baloo Bhai 2;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: $black;
}

.add-post__step-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 672px;
  margin: 0 auto;
}

.add-post__bottom-container {
  width: 440px;
  display: flex;
  align-self: center;
  margin-top: 140px;
  &.third-step {
    margin-top: 24px;
  }
}

@media (max-width: 435px) {
  .add-post-page {
    margin: 0 16px;
    height: 100%;
    width: auto;
  }

  .add-post-container {
    margin: 0 16px;
    height: 100%;
  }

  .add-post__bottom-container {
    width: 328px;
    margin-top: 15%;
  }

  .add-post-title {
    font-size: 24px;
  }

  .add-post__step-container {
    width: 328px;
    margin: 0 auto;
  }

  .helper-post-container {
    width: auto;
    align-items: flex-start;
  }
  .helper-post-description {
    text-align: left;
  }

  .add-image-container {
    gap: 16px;
    flex-wrap: wrap;
  }
}
