@import '../../../../sassStyles/common';

.landing-header-container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 104px;
    box-shadow: 0 9px 18px 0 rgba(0, 0, 0, 0.07);
    background-color: $primary;
    z-index: 3;
    
    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        margin: 28px 32px;

        >img {
            height: 48px;
            flex-grow: 0;
            object-fit: contain;
            cursor: pointer;
        }

        .links-container {
            height: 22px;
            flex-grow: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 64px;
            padding: 0;

            .link {
                height: 22px;
                flex-grow: 0;
                font-family: Baloo Bhai 2;
                font-size: 16px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.38;
                letter-spacing: normal;
                text-align: left;
                color: $white;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 1095px) {
    .landing-header-container {
        height: 72px;
        .links-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 48px 140px 214px 24px;
            gap: 48px;
            background-color: $primary;
            border-top: solid 1px $white;

            .link {
                font-size: 26px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px;
                color: $white;
                cursor: pointer;
            }
        }
        .content {
            margin: 12px 12px;
            .hamburger-icon {
                width: 24px;
                height: 24px;
                flex-grow: 0;
                object-fit: contain;
                cursor: pointer;
            }
        }
    }
}