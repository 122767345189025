$white: #fff;
$black: #111;
$primary: #3849da;
$secondary: #004c51;
$active: #34f2d7;
$error: #cc0000;
$disabled: #ccc;
$counter: #676767;
$btn-primary: #34f2d7;
$msg-info: #e8f8f9;
$msg-warning: #fffaeb;
$msg-error: #fee5e5;
$msg-correct: #f0f8f0;
$border-info: #23779a;
$border-warning: #856300;
$border-success: #008035;
$border-error: #c00;
$msg-description-color: #111111;
$card-border-selected: #17ae99;
$card-background-selected: #c2f3ec;
$add-image-background: #f0f0f0;
$landing-step-background: #c2f3ec;
$copyright-background: #101e96;

img.Close {
  width: 24px;
  height: 24px;
  flex-grow: 0;
  object-fit: contain;
}

@mixin material-effect($color) {
  cursor: pointer;
  // Use box-shadow to create a subtle shadow around the div
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  // Use transition to animate the box-shadow on hover and active states
  transition: box-shadow 0.3s ease;

  // Use a pseudo-element to create a ripple effect on click
  position: relative;
  overflow: hidden;

  &::after {
    // Position the pseudo-element over the div
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // Use pointer-events to prevent the pseudo-element from interfering with clicks
    pointer-events: none;

    // Use transform and opacity to hide the pseudo-element by default
    transform: scale(0);
    opacity: 0;

    // Use background-color to set the ripple color
    background-color: $color;

    // Use border-radius to make the ripple circular
    border-radius: 50%;

    // Use transition to animate the transform and opacity on click
    transition:
      transform 0.5s ease,
      opacity 0.5s ease;
  }

  // Change the box-shadow on hover to create a lifted effect
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  // Change the box-shadow on active to create a pressed effect
  &:active {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}