@import '../../../../sassStyles/common';

.arrow-navigation-container {
    width: 24px;
    height: 24px;
    padding: 4px;
    opacity: 0.8;
    background-color: $white;

    img {
        width: 16px;
        height: 16px;

        &.right {
            transform: rotate(90deg);
        }

        &.down {
            transform: rotate(180deg);
        }

        &.left {
            transform: rotate(270deg);
        }
    }
}