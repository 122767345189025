@import '../../../sassStyles/common';

.home__container {
  display: flex;
  flex-direction: column;
  max-width: 328px;
  margin: 0 auto;
}

.home__search-card {
  display: flex;
  gap: 30px;
  align-items: center;
}

.home__search-card-label {
  font-size: 26px;
  font-weight: 600;
  color: $black;
}

.home__search-anchor {
  display: flex;
  color: $primary;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  gap: 4px;
}

@media (min-width: 435px) {
  .home__container {
    width: 1024px;
    max-width: unset;
    margin: 42px auto 0 310px;
  }
}
