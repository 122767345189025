// Define a custom animation for fading in and out
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

// Apply the animation to the component with the fade mixin
@mixin fade-in() {
    animation: fade-in 0.5s ease-in;
}

@mixin fade-out() {
    animation: fade-out 0.5s ease-out;
}

@mixin fade-out-and-fade-in($name, $duration, $timing-function, $iteration-count, $fill-mode) {
    -webkit-animation: $name $duration $timing-function $iteration-count $fill-mode;
    -moz-animation: $name $duration $timing-function $iteration-count $fill-mode;
    -o-animation: $name $duration $timing-function $iteration-count $fill-mode;
    animation: $name $duration $timing-function $iteration-count $fill-mode;
}
  
@keyframes fade-out-and-fade-in {
    /* Start with opacity 0 */
    0% {
      opacity: 1;
    }
    /* Fade out to opacity 0 at 50% */
    50% {
      opacity: 0;
    }
    /* Fade in to opacity 1 at 100% */
    100% {
      opacity: 1;
    }
}

@mixin fade-out-and-fade-in {
    animation: fade-out-and-fade-in 0.5s ease-in-out infinite alternate;
}